import { filter } from 'lodash'
import { action, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import 'moment-timezone'
import { msg } from 'stores/msg'
import type {
  Advisor,
  AdvisorInput,
  Practice as PracticeType
} from 'types/graphql'
import { Practice as GQLPractice, Maybe, ResourcesDeteled } from 'types/graphql'
import practiceService from '../services/practice.service'

function toBool(input: any) {
  return input ? Boolean(JSON.parse(input)) : false
}

export interface CaptureGeniuesConfig {
  scheduleDeletion: {
    enabled: boolean
    days: number
    sources: ResourcesDeteled
  }
}

export interface PracticeConfig {
  captureGenius: CaptureGeniuesConfig
}

class Practice {
  statuses = []
  types = []

  allMembers: Advisor[] = [] // includes isAdmin
  members: Advisor[] = [] // isAdmin filtered out
  id = 0
  name = ''
  type = ''
  userPool = ''
  domain = ''
  desc = ''
  phone = ''
  logoUrl = ''
  bucket = ''
  address1 = ''
  address2 = ''
  defaultCc = ''
  defaultBcc = ''
  secureFieldPrefix = ''
  city = ''
  state = ''
  country = ''
  locale = ''
  tz = ''
  zip = ''
  enabled = false
  permissions = []
  planId = null
  defaultReadReceipt = false
  createdAt = ''
  legalBucket = false
  syncSummaryCrm = false
  config?: PracticeConfig

  constructor() {
    makeObservable(this, {
      statuses: observable,
      types: observable,
      allMembers: observable,
      members: observable,
      id: observable,
      name: observable,
      type: observable,
      userPool: observable,
      domain: observable,
      desc: observable,
      phone: observable,
      logoUrl: observable,
      bucket: observable,
      address1: observable,
      address2: observable,
      defaultCc: observable,
      defaultBcc: observable,
      secureFieldPrefix: observable,
      city: observable,
      state: observable,
      country: observable,
      locale: observable,
      tz: observable,
      zip: observable,
      enabled: observable,
      permissions: observable,
      defaultReadReceipt: observable,
      planId: observable,
      createdAt: observable,
      updateMember: action.bound,
      setPractice: action.bound,
      setStatuses: action.bound,
      setTypes: action.bound,
      update: action.bound
    })
  }

  /**
   *
   */
  setPractice(
    practice: Maybe<GQLPractice> | undefined,
    advisor: Maybe<Advisor> | undefined
  ) {
    global.perms.permissions = practice?.permissions ?? []

    const isAdmin = !!(advisor && advisor.isAdmin)
    const isTest = practice?.domain?.startsWith('test_')
    const isProd = process.env.REACT_APP_ENV !== 'dev'

    // for production practices, that are non-test accounts, filter
    // out all admin advisors, so that they don't appear in the app
    // if the logging in advisor is not an admin advisor
    if (isProd && !isTest && !isAdmin) {
      if (practice) {
        practice.members = filter(
          practice?.members ?? [],
          (m) => !toBool(m.isAdmin)
        )
      }
      this.allMembers = practice?.members ?? []
    }
    Object.assign(this, practice ?? {})
    const timezone = practice?.tz
    timezone ? moment.tz.setDefault(timezone) : moment.tz.setDefault()
    window.moment = moment
  }

  /**
   *
   */
  setStatuses(statuses: any) {
    this.statuses = statuses
  }

  /**
   *
   */
  setTypes(types: any) {
    this.types = types
  }

  /**
   * Add a new member to the practice, if not exists
   */
  addMember = (member: Advisor) => {
    const mmbr = this.getMember(member.id)
    if (!mmbr) {
      this.members.push(member)
    }
  }

  /**
   * Find a practice member
   */
  getMember = (id: string | number) => {
    return this.members.find((m) => m.id === id)
  }

  /**
   * Find a practice member
   */
  updateMember = (id: string | number, fields: Partial<AdvisorInput>) => {
    const member = this.members.find((m) => m.id === id)
    if (member) {
      runInAction(() => {
        Object.keys(fields).forEach(
          (key) =>
            (member[key as keyof Advisor] = fields[key as keyof AdvisorInput])
        )
      })
    }
    return member
  }

  /**
   * Delete an advisor from a practice
   * @param {*} advisor
   */
  async update(fields: Partial<PracticeType>, showMsg = true) {
    try {
      const updated = await practiceService.updatePractice(this.id, {
        ...fields
      })
      if (showMsg && updated) {
        msg.success('Practice updated successfully', '', 3)
      }
      runInAction(() => {
        Object.keys(fields).forEach((key) => {
          ;(this as any)[key] = fields[key as keyof PracticeType]
        })
        fields.tz ? moment.tz.setDefault(fields.tz) : moment.tz.setDefault()
      })
    } catch (err) {
      msg.error('Ups, an error ocurred while updating the Practice')
    }
  }
}

export default Practice
